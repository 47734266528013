// Check Browser Width




/*=============================================
=        Global Variable Declarations         =
=============================================*/


const viewportWidth = window.innerWidth || document.documentElement.clientWidth;


// function isLargeSize(isLarge) {
//     return (isLarge ? '$2.00' : '$10.00');
// }


var isLargeScreen = viewportWidth > 992 ? true : false;

// General Elements

var mainDiv = document.querySelector('main'),
    mainlogoani = document.querySelector('#main-logo-ani'),

    // Nav Elements

    hammy = document.querySelector('#hammy'),
    navList_class = ['sld-pg-1', 'sld-pg-2', 'sld-pg-3', 'sld-pg-4', 'sld-pg-5', 'sld-pg-6'],
    extrasDiv = document.createElement('div'),


    // Footer Elements

    footerLinks = document.querySelector('#ftr-links'),
    footerContent = document.querySelector('#footer-content'),
    footerDiv = document.querySelector('#footer-content'),
    footerCog = document.querySelector('#footer-cog'),
    footerOverlay = document.querySelector('#overlay'),
    pageSlide = document.querySelectorAll('main section');

//  Link Buttons

const slideLinks = document.querySelectorAll('.sldlink'),
    linkButton1 = document.querySelectorAll('.link1'),
    linkButton2 = document.querySelectorAll('.link2'),
    linkButton3 = document.querySelectorAll('.link3'),
    linkButton4 = document.querySelectorAll('.link4'),
    linkButton5 = document.querySelectorAll('.link5'),
    linkButton6 = document.querySelectorAll('.link6');


// Page Switching Arrays

const linkButtons = [linkButton1, linkButton2, linkButton3, linkButton4, linkButton5, linkButton6],
    navLinks = [nav_to_page1, nav_to_page2, nav_to_page3, nav_to_page4, nav_to_page5, nav_to_page6],
    pageLink = [pageLink1, pageLink2, pageLink3, pageLink4, pageLink5, pageLink6];


/*=====  End of Global Variable Declarations  ======*/



/*=============================================
=            Page Title Switch                =
=============================================*/


function nav_title_load(title) {
    var thetitle = document.querySelector('#page-title');
    thetitle.innerHTML = title;
}

/*=====  End of Page Title Switch ======*/




/*=============================================
=          Run the Slide Functions            =
=============================================*/


function nav_to_page1() {
    nav_to_page[0]();
}

function nav_to_page2() {
    nav_to_page[1]();
}

function nav_to_page3() {
    nav_to_page[2]();
}

function nav_to_page4() {
    nav_to_page[3]();
}

function nav_to_page5() {
    nav_to_page[4]();
}

function nav_to_page6() {
    nav_to_page[5]();
}


/*=====  End of Run the Slide Functions  ======*/



/*=============================================
=    Run the Stagger Page Switch Animation    =
=============================================*/


currentSlideNum = 1;

function switch_stripes(linknum) {

    var slideattr = slideLinks[linknum].getAttribute('data-linknum');


    if (slideattr < currentSlideNum && (mainDiv.getAttribute("data-slide") === "true")) {

        switch_stripes_reverse();

    } else if (slideattr > currentSlideNum && (mainDiv.getAttribute("data-slide") === "true")) {

        switch_stripes_forward();

    } else {

    }

    currentSlideNum = slideattr;
}


/*=====  End of Run the Stagger Page Switch Animation  ======*/





/*=============================================
=            Hamburger and Menu Play          =
=============================================*/





hamburger.addEventListener('click', function () {


    if (isLargeScreen) {
        if (hamburger.classList.contains('is-active')) {
            if (!page_title.isActive() && !nav_tl.isActive()) {

                hamburger.classList.remove('is-active');
                nav_tl.reverse();
                if (isLargeScreen) {
                    gsap.delayedCall(1.1, function () {
                        page_title.play();
                    });
                } else {
                    page_title.play();
                }
            }

        } else {
            if (!page_title.isActive() && !nav_tl.isActive()) {

                nav_tl.play();
                page_title.reverse();

            }
        }
    }
});


/*=====  End of Hamburger and Menu Play  ======*/




/*=============================================
=           Process Top Nav Links             =
=============================================*/


(function () {
    var current = location.pathname;
    if (current === "/" || "") {
        navList[0].classList.add("link-active");
    } else {
        for (var i = 0, len = navList.length; i < len; i++) {
            if (navList[i].getAttribute("href").indexOf(current) !== -1) {
                navList[i].classList.add("link-active");
            }
        }
    }
})();



if (isLargeScreen) {

    /*----------  Insert Footer on Desktop  ----------*/


    var bottom_nav = document.querySelector('#navigation');

    extrasDiv.setAttribute('id', 'extras');
    footerContent.prepend(extrasDiv);


    hammy.insertAdjacentElement('afterend', bottom_nav);



    // Remove cog and replace with Nav (For Mobile)

} else {

    footerCog.remove();
    footerAct.appendChild(hammy);
}


/*=====  End of Process Top Nav Links   ======*/




/*=============================================
=             Slide Button Click              =
=============================================*/


var l = 0;
linkButtons.forEach(function (links) {
    var r = l;
    links.forEach(function (btn) {
        btn.addEventListener('click', function (event) {
            event.preventDefault();
            if (typeof pageLink[r] !== 'undefined') {
                mainDiv.setAttribute("data-slide", "true");
                navLinks[r]();
            }
        });
    });
    l++;
});


/*=====  End of Slide Button Click  ======*/





/*=============================================
=      Raise Footer and Overlay Toggle        =
=============================================*/


function raise_footer() {
    if (!isLargeScreen) {
        nav_tl.play();
    }
    page_title.reverse();
    footerAct.classList.add('active');
    footerDiv.classList.add('active');
    footerLinks.classList.add('active');
    footerOverlay.classList.add('active');
    if (isLargeScreen) {
        footer_links_stagger();
    }
}

function lower_footer() {
    nav_tl.reverse();
    page_title.play();
    footerAct.classList.remove('active');
    footerDiv.classList.remove('active');
    footerLinks.classList.remove('active');
    footerOverlay.classList.remove('active');
    hamburger.classList.remove('is-active');

}


function add_switch_overlay() {


    footerOverlay.classList.add('transition');
    setTimeout(function () {
        footerOverlay.classList.remove('transition');
    }, 600);
}

if (!isLargeScreen) {
    pageSlide[0].appendChild(footerLinks);
}


// Botton or Overlay Click

[footerAct, footerOverlay].forEach(item => {
    item.addEventListener('click', () => {
        if (isLargeScreen) {
            if (!footerOverlay.classList.contains('active') && hamburger.classList.contains('is-active')) {
                nav_tl.reverse();
                raise_footer();
                hamburger.classList.remove('is-active')
            } else if (!footerOverlay.classList.contains('active') && !hamburger.classList.contains('is-active')) {
                raise_footer();
            } else {
                lower_footer();
            }
        } else {
            if (!footerOverlay.classList.contains('active') && !hamburger.classList.contains('is-active')) {
                raise_footer();
            } else {
                lower_footer();
            }
        }
    })
})

// footerOverlay.addEventListener('click', () => {
//     if (footerOverlay.classList.contains('active')) {
//         raise_footer();
//     }
// })


/*=====  End of Raise Footer and Overlay Toggle  ======*/




/*=============================================
=     Add URL Nav Query String & Slide        =
=============================================*/


var nav_to_page = [];


function createfunc(i) {
    return function () {

        if (typeof pageLink[i] !== 'undefined') {
            switch_stripes(i);
            if (history.pushState) {
                if (navList_class[i] !== 'sld-pg-1') {
                    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?page=' + pageLink[i][0];
                } else {
                    var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
                }
                window.history.pushState({
                    path: newurl
                }, '', newurl);
            }


            /*----------  Add Page Class  ----------*/


            if (navList_class[i] !== 'sld-pg-1') {
                mainDiv.className = '';
                mainDiv.classList.add(navList_class[i]);
            } else {
                mainDiv.className = '';
            }


            /*----------  Switch Page Title  ----------*/


            nav_title_load(pageLink[i][1]);
            document.title = pageLink[i][1] + " | Suasion Media";


            /*----------  Append Footer  ----------*/


            if (!isLargeScreen) {
                pageSlide[i].appendChild(footerLinks);
            }
        }
    };
}


/*----------  Loop Through and Increment Function ----------*/


for (var i = 0; i < navLinks.length; i++) {
    nav_to_page[i] = createfunc(i);
};


/*=====  End of Add URL Nav Query String & Slide ======*/




/*=============================================
=            Link Check and Redirect          =
=============================================*/


i = 1;

function link_check() {
    pageLink.forEach(function (links) {

        if (typeof pageLink[i] !== 'undefined') {
            if (window.location.href.indexOf(pageLink[i][0]) > -1) {
                navLinks[i]();
            }
        }
        i++;
    });
}

link_check();


/*=====  End of Link Check and Redirect  ======*/




/*=============================================
=       Resize Responsive Correction          =
=============================================*/


var lastX = viewportWidth

function navReload() {
    var x = window.innerWidth || document.documentElement.clientWidth;
    if (lastX > 992 && x <= 991) {
        location.reload();
    } else if (lastX <= 991 && x > 992) {
        location.reload();
    }
    lastX = x
}


window.addEventListener('resize', navReload);


/*=====  End of Resize Responsive Correction  ======*/




// ------------------------------------------------------------------------------------------------




/*=============================================
=         Signup Email Functionality          =
=============================================*/


var email = document.querySelector('#sub_email'),
    subscribe_button = document.querySelector('#subscribe_button');

function validateEmail(email) {
    var ex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return ex.test(email);
}

email.addEventListener('keydown', function () {
    var email = this.value;

    if (validateEmail(email)) {
        subscribe_button.classList.add('is-active');
    }
});

subscribe_button.addEventListener('click', function (e) {
    e.preventDefault();
    this.classList.add('is-done', 'is-active');

    setTimeout(function () {
        subscribe_button.innerHTML = 'Thanks! You have been added.';
    }, 500);
});


/*=====  End of Signup Email Functionality  ======*/




/*=============================================
=         Mobile Scroll Functionality         =
=============================================*/


var scrollW = document.querySelector("#wrap-scroll");
var scrollUl = document.querySelector("#ul-scroll");
var itemsScrolled,
    itemsMax,
    cloned = false;

var listOpts = {
    itemCount: null,
    itemHeight: null,
    items: [],
};

function scrollWrap() {

    itemsScrolled = Math.ceil((this.scrollTop + listOpts.itemHeight / 2) / listOpts.itemHeight);

    if (this.scrollTop < 1) {
        itemsScrolled = 0;
    }

    listOpts.items.forEach(function (ele) {
        ele.classList.remove("active");
    })

    if (itemsScrolled < listOpts.items.length) {
        listOpts.items[itemsScrolled].classList.add("active");
    }

    if (itemsScrolled > listOpts.items.length - 3) {

        var node;
        for (_x = 0; _x <= itemsMax - 1; _x++) {

            node = listOpts.items[_x];

            if (!cloned) {
                node = listOpts.items[_x].cloneNode(true);
            }

            scrollUl.appendChild(node);
        }

        initItems(cloned);
        cloned = true;
        itemsScrolled = 0;

    }
}

function initItems(scrollSmooth) {

    listOpts.items = [].slice.call(scrollUl.querySelectorAll("li"));
    listOpts.itemHeight = listOpts.items[0].clientHeight;
    listOpts.itemCount = listOpts.items.length;

    if (!itemsMax) {
        itemsMax = listOpts.itemCount;
    }

    if (scrollSmooth) {
        var seamLessScrollPoint = ((itemsMax - 3) * listOpts.itemHeight);
        scrollW.scrollTop = seamLessScrollPoint;
    }

}

document.addEventListener("DOMContentLoaded", function (event) {
    initItems();
    scrollW.onscroll = scrollWrap;
});


/*=====  End of Mobile Scroll Functionality  ======*/
